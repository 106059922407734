.settings__content--form {
  padding-top: 58px;
  .form__item--day {
    margin-left: 30px;
    height: 32px;
    line-height: 32px;
    text-align: center;
  }
}

.redemption-point-form {
  &__body {
    background-color: white;
    .ant-table-content {
      overflow: auto hidden;
      > table {
        width: max-content;
        min-width: 100%;
        table-layout: auto;
      }
    }
    .ant-table-cell {
      text-align: center;
    }
  }
  .ant-spin-nested-loading {
    min-height: 400px;
  }
}
