@import '../../../assets/scss/variables';
.cv-detail-temp {
  .header-list {
    margin-bottom: 0;
  }
}

.cv-detail {
  /* Scroll bar */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(185, 185, 185);
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(139, 138, 138);
  }
  &-box + &-box {
    margin-top: 20px;
  }
  .step-flow {
    margin-bottom: 20px;
    h3 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
    .steps {
      padding-top: 16px;
      padding-bottom: 12px;
      overflow-x: auto;
      user-select: none;
      text-transform: uppercase;
      .ant-steps-item-active {
        cursor: pointer;
        .ant-steps-item-title {
          font-weight: 700;
        }
      }
      .failed .ant-steps-item-title {
        color: #ff4d4f;
      }
      .step-circle {
        $size: 24px;
        background: $primary-color;
        color: white;
        width: $size;
        height: $size;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 12px;
      }
      .step-circle.disabled {
        background: #bfbfbf;
      }
      .active-show {
        .ant-steps-item-title {
          color: $primary-color;
        }
      }

      // color for tail
      .ant-steps-item-process .ant-steps-item-tail::after,
      .ant-steps-item-wait .ant-steps-item-tail::after {
        background: #dfdfdf;
      }
    }
  }

  .cv-detail-box {
    .cv-information {
      height: 100%;
    }
    .page-box-shadow {
      height: 100%;
    }
  }

  .step-input-wrap {
    .page-box-shadow {
      padding: 16px 0;
      height: 100%;
    }
  }

  .step-main {
    padding: 0 16px;
    overflow-y: auto;
    height: 100%;
    .step-frame {
      .step-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f2f2f2;
        padding: 10px;
        .title-slash {
          font-weight: 700;
          font-size: 16px;
          margin: 0 4px;
        }
        h4 {
          margin-bottom: 0;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 16px;
          &.switch-title {
            &.active {
              color: $primary-color;
            }
            cursor: pointer;
            transition: color 0.15s linear;
            &:hover {
              color: $primary-color;
            }
          }
        }
        button {
          text-transform: uppercase;
        }
      }
      .step-content {
        margin-top: 8px;
        margin-bottom: 14px;
      }
    }

    .step-content.spin-wrapper {
      .ant-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .cv-left {
    overflow-y: auto;
    margin-bottom: 24px;
  }

  .cv-avatar-wrapper {
    height: fit-content;
    overflow: auto;
  }

  .cv-information {
    .page-box-shadow {
      padding: 16px;
      .cv-avatar {
        .avatar-show {
          padding: 0;
          box-shadow: none;
          .list > * {
            $size: 14%;
            width: $size;
            height: $size;
          }
        }
      }
      .cv-avatar-step {
        padding-left: 8px;
        padding-right: 8px;
        background-color: #fff;
        .avatar-step-wrapper {
          display: flex;
          align-items: center;
          height: 100%;
          .divider {
            margin: 0 3px;
          }
          .email-icon {
            margin-left: 8px;
            display: flex;
            align-items: center;
            .anticon {
              font-size: 18px;
              color: #808080;
            }
          }
        }
      }

      .edit-detail-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 1;
        &.right-8 {
          right: 8px;
        }
      }

      .cv-right {
        position: relative;
        margin-left: 8px;
        .cv-info-line {
          display: flex;
          > p {
            width: 140px;
          }
          > div {
            flex: 1;
            word-break: break-word;
          }
          .cv-basic-info {
            display: flex;
            gap: 10px;
            align-items: center;
            .cv-line-gender {
              display: flex;
              min-width: 55px;
              justify-content: center;
            }
          }
          .ant-breadcrumb {
            display: flex;
            > span {
              display: flex;
            }
            .ant-breadcrumb-link {
              display: flex;
              align-items: center;
              > span {
                position: relative;
                top: 1px;
              }
            }
            img {
              height: 12px;
              margin-right: 2px;
            }
          }
        }

        .cv-info-more-content {
          height: auto;
          overflow: hidden;
          &.hidden {
            height: 0;
          }
        }

        .show-more-btn-wrapper {
          text-align: center;
        }

        .showmore-btn {
          text-decoration: underline;
          display: inline-block;
          color: $primary-color;
          cursor: pointer;
        }
      }
    }
  }

  .cv-showmore {
    color: $primary-color;
    cursor: pointer;
  }

  .show-history-btn {
    margin: 16px 0;
  }
}

.ant-drawer-body {
  .table-main {
    margin: 0;
    .box-shadow {
      margin: 0;
    }
  }
}
.cv-right-tabs {
  .ant-tabs-nav-wrap {
    background-color: #f2f2f2;
    padding: 2px 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-tabs-tab {
      margin-left: 8px;
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab-btn {
      margin-bottom: 0;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

@media screen and (min-width: 992px) {
  .cv-detail {
    .cv-information {
      .page-box-shadow {
        padding: 16px 16px 16px 0;
        .cv-right {
          .show-more-btn-wrapper {
            text-align: left;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .cv-detail {
    --form-content-height: calc(100vh - 340px);
    .cv-information {
      .page-box-shadow {
        padding: 16px;
        max-height: var(--form-content-height);
        overflow-y: auto;
      }
    }
    .cv-infor-container {
      max-height: var(--form-content-height);
      overflow-y: scroll;
    }
    .step-input-wrap {
      max-height: var(--form-content-height);
    }
  }
}

@media screen and (min-width: 1600px) {
  .cv-detail {
    --form-content-height: calc(100vh - 340px);
    .cv-information {
      height: 100%;
      .page-box-shadow {
        height: 100%;
        max-height: unset;
        padding: 16px 16px 16px 0;
        overflow-y: hidden;
        .cv-right {
          max-height: calc(var(--form-content-height) - 32px);
          overflow-y: auto;
          .show-more-btn-wrapper {
            display: none;
          }
          .cv-info-more-content {
            &.hidden {
              height: fit-content;
            }
          }
        }
      }
    }
    .cv-detail-box {
      max-height: var(--form-content-height);
    }

    .cv-infor-container {
      max-height: var(--form-content-height);
      overflow-y: auto;
    }
    .cv-left {
      margin-bottom: 0;
      max-height: calc(var(--form-content-height) - 32px);
    }
  }
}
