.core-picker {
  .ant-input-affix-wrapper {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-picker {
    align-self: stretch;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ant-picker-input > input {
    display: none;
  }
  .ant-picker-suffix {
    margin-left: 0;
  }
}
