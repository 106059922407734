//hover mouse over hex color code for information

$primary-color: var(--ant-primary-color); //#fb9122
$primary-color-hover: var(--ant-primary-color-hover); //#ffaf4d
$primary-color-active: var(--ant-primary-color-active); //#ad5207
$primary-color-light-hover: var(--ant-primary-1); //#fffaf0

$secondary-color: #192c3e;

$success-color: var(--ant-success-color); //#fb9122
$success-color-active: var(--ant-success-color-active); //#389e0d
$success-color-hover: var(--ant-success-color-hover); //#73d13d

$warning-color: var(--ant-warning-color); //#faad14
$warning-color-active: var(--ant-warning-color-active); //#d48806
$warning-color-hover: var(--ant-warning-color-hover); //#ffc53d

$error-color: var(--ant-error-color); //#ff4d4f
$error-color-active: var(--ant-error-color-active); //#d9363e
$error-color-hover: var(--ant-error-color-hover); //#ff7875

$info-color: var(--ant-info-color); //#2271b1
$info-color-active: var(--ant-info-color-active); //#093563
$info-color-hover: var(--ant-info-color-hover); //#428abd

$text-color: rgba(0, 0, 0, 0.85);
$icon-size: 1.25rem;
