.reminder {
  .reminder-header {
    padding: 22px 24px 18px;
    box-shadow: 0px 1px 1px 0px #00000040;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    h1 {
      display: inline-block;
      margin-bottom: 0;
      font-size: 20px;
    }
    .today {
      margin-left: 16px;
    }
    .month-picker {
      border: none;
      input {
        font-size: 16px;
        text-align: center;
        width: 80px;
      }
    }
    .icon {
      padding: 14px;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .reminder-calendar {
    thead {
      th {
        text-align: center;
        font-weight: 700;
      }
    }

    .reminder-cell-list {
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative;
      li {
        display: flex;
        align-items: center;
        .reminder-cell-list--icon {
          display: inline-block;
          flex-shrink: 0;
          width: 5px;
          height: 5px;
          margin-right: 5px;
          background-color: gray;
          border-radius: 50%;
        }
        .reminder-cell-list-text-truncate {
          -webkit-line-clamp: 1;
          margin-right: 4px;
        }
      }
      .reminder-cell-list-more {
        position: absolute;
        left: 50%;
        bottom: -20px;
      }
    }
  }
  .reminder-detail {
    padding: 0 10px;
    h2 {
      text-align: center;
      padding-top: 8px;
      padding-bottom: 15px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 700;
    }

    .reminder-detail-item {
      min-height: 108px;
      display: flex;
      cursor: pointer;

      .reminder-detail-bar {
        width: 4px;
        min-height: inherit;
      }

      .reminder-detail-item-body {
        border-bottom: 1px solid #bfbfbf;
        flex: 1;
        display: flex;
        margin-top: 10px;
        .reminder-detail-position {
          width: 22%;
          margin-left: 10px;
          margin-right: 10px;
        }
        .reminder-detail-description {
          flex: 1;
          .reminder-detail-fullName {
            font-weight: 700;
          }
        }
      }
    }

    .reminder-detail-item:hover {
      background-color: #e8f4ff;
    }

    .reminder-detail-no-data {
      margin-top: 150px;
      text-align: center;
      span {
        margin-left: 8px;
        font-size: 18px;
        font-weight: 300;
        font-style: italic;
      }
    }
  }
}
