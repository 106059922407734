.table-filter {
  padding: 0 24px;
  button {
    align-self: flex-end;
    margin-left: 5px;
    margin-right: 5px;
  }
  .field-row {
    > .ant-col {
      .ant-form-item {
        margin-bottom: 0;
        .ant-form-item-label {
          padding-bottom: 0;
        }
      }
    }
    .cleaner {
      border-radius: 30%;
    }
  }
}
