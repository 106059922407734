.custom-slider {
  .ant-slider-handle-1 {
    left: var(--slider-left-2) !important;
  }

  .ant-slider-track {
    left: var(--slider-left-2) !important;
    width: var(--width) !important;
  }
  .ant-slider-handle:hover {
    background-color: red;
  }
}
