.update-form-box {
  border-radius: 4px;
  .avatar-show {
    padding: 16px;
  }
  .ant-form-item {
    flex-direction: column;
    &.cv-field {
      flex-direction: row;
    }
    .ant-form-item-label {
      text-align: left;
    }
  }
}

@media screen and (min-width: 1200px) {
  .update-form-box {
    .ant-form-item {
      &.cv-field {
        flex-direction: column;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .update-form-box {
    .avatar-show {
      padding: 34px;
    }
  }
}
