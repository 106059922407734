@import '../../../../../assets/scss/variables';

.form-list-item-wrapper {
  .ant-form-item-control-input-content {
    display: flex;
    .ant-form-item {
      flex: 1;
    }
    .dynamic-delete-button {
      font-size: 20px;
      margin-top: 6px;
      margin-left: 8px;
    }
    .ant-form-item-extra {
      color: $info-color-hover;
    }
  }
}
