//utils
.text-center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.d-flex {
  display: flex !important;
  flex-wrap: wrap !important;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.mr-5 {
  margin-right: 5px;
}
.ml-20 {
  margin-left: 1.25rem;
}

.error-color {
  color: $error-color;
}

.icon-size {
  font-size: $icon-size;
}

.warning-icon {
  color: $warning-color;
  @extend .icon-size;
}

.error-icon {
  @extend .error-color;
  @extend .icon-size;
}

.success-icon {
  @extend .icon-size;
  color: $success-color;
}

.text-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.icon-color--active {
  color: $primary-color;
}

.icon-color {
  color: $text-color;
}

// ant css overide
.ant-tooltip-inner {
  background-color: $secondary-color;
}

.full-height {
  height: 100%;
}
