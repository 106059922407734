@import './common';
.sidebar {
  display: block;
  width: 200px;
  height: calc(100vh - 64px);
  box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.05);
  position: fixed;
  left: 0;
  top: 64px;
  bottom: 0;
  z-index: 10;
  overflow: auto;
  background: transparent !important;
  &__menu {
    height: 100%;
  }
  .ant-menu-item:hover {
    background-color: $primary-color-light-hover;
  }
}

.ant-menu-submenu-title .ant-menu-item-icon + span {
  transition: cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}

.ant-menu-item .ant-menu-item-icon + span {
  transition: cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
}

.ant-menu .ant-menu-submenu:last-child .ant-menu-item-only-child:last-child {
  margin-bottom: 44px;
}

.ant-layout-sider-has-trigger {
  transition: none;
}

.ant-layout-sider-trigger {
  background-color: $secondary-color;
  transition: none;
}

.cantScroll {
  height: 100%;
  overflow: hidden;
}

// Responsive
$breakpoint_sidebar: $md;
@media (max-width: $breakpoint_sidebar) {
  .main__content {
    padding-left: 0 !important;
  }
  .sidebar {
    display: none;
    position: fixed;
    max-width: 100% !important;
    width: 100% !important;
    background: rgba(0, 0, 0, 0.3) !important;
    animation: fadeIn 0.4s forwards ease-in-out;
    padding-bottom: 0;
    .ant-layout-sider-children {
      overflow-y: auto;
      max-width: 70%;
      width: 70%;
      animation: slideToRight 0.4s forwards ease-in-out;
    }
  }

  .show {
    display: block;
  }

  @keyframes slideToRight {
    0% {
      transform: translateX(-200px);
    }

    100% {
      transform: translateX(0);
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
}

:export {
  sidebar: $breakpoint_sidebar;
}
