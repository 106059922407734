.position {
  &-form {
    .redemption-item {
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }
    .redemption-switch {
      display: flex;
      justify-content: space-between;
      &__desc {
        color: rgba(0, 0, 0, 0.45);
      }
    }
  }
}
