.avatar-show {
  padding: 35px 34px 45px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  .avatar {
    cursor: pointer;
    .img-zoom-lens {
      position: absolute;
      border: none;
      width: 40px;
      height: 40px;
    }
    .img-zoom-lens:hover {
      border: 1px solid #d4d4d4 !important;
    }

    .avatar-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .delete-btn {
      cursor: pointer;
      border-radius: 2px;
      position: absolute;
      background-color: white;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 6px 6px 3px;
      right: 9px;
      bottom: 9px;
    }
  }
  .list {
    display: flex;
    gap: 10px;
    margin-top: 25px;
    justify-content: center;
    flex-wrap: wrap;
    > * {
      cursor: pointer;
      width: 40px;
      height: 40px;
    }

    button {
      color: #808080;
      width: 40px;
      height: 40px;
    }

    button:hover {
      color: var(--ant-primary-color);
    }
  }
}

.avatar-zoom-result {
  position: absolute;
  border: 1px solid #d4d4d4;
  width: 100%;
  opacity: 0;
  z-index: -1;
  transition: opacity linear 0.3s;
  box-shadow: 0px 2px 6px 0px #727272;
}
