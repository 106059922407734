$bg-dark: #1d243d;

.login {
  background-color: $bg-dark;
  color: #dfdeee;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .logo {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
  }

  &__text {
    color: #dfdeee;
  }

  #tsparticles {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
  }

  h1.ant-typography {
    top: 200px;
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
  }

  &-form {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgb(33, 41, 66);
    border-radius: 9px;
    border-top: 10px solid $primary-color;
    border-bottom: 10px solid $error-color;
    padding: 1.5rem;
    width: 410px;
    box-shadow: 1px 1px 108.8px 19.2px rgb(25, 31, 53);
    .ant-card-head-title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__content {
      margin: 6rem 0 3rem 0;
    }

    &__info {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 1rem;
    }

    .ant-form,
    .ant-form-item,
    .ant-form-item-label > label {
      color: inherit;
    }
  }
}

.login-modal {
  background-color: $bg-dark;
  .ant-modal-content,
  .ant-modal-header {
    background-color: inherit;
  }
  .ant-modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.253);
  }
  .ant-modal-title,
  .ant-modal-close-x,
  .ant-typography {
    color: white;
  }
}
