@import './sidebar.scss';
.header {
  width: 100%;
  padding: 0 16px;
  background: $secondary-color;
  box-shadow: inset 0px -1px 0px #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  & a {
    text-decoration: none;
    color: #fff !important;
  }

  &__bars {
    display: none;
    line-height: normal;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    img {
      width: 30px;
    }
  }

  &__logo {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    text-transform: uppercase;
    & > a > span {
      margin: 0 12px;
    }
  }
  &__add {
    display: flex;

    &--actions {
      color: #fff !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 25px;
      margin-left: 20px;
    }
    &--user .user__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 8px;
    }
  }
}

// Responsive
@media (max-width: $breakpoint_sidebar) {
  .header {
    &__bars {
      display: block;
    }
    &__bars:active {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
    .anticon-logout {
      color: white;
      svg {
        width: 18px;
        height: 18px;
      }
    }

    &--actions {
      > a {
        display: flex;
      }
    }

    .anticon-logout {
      width: 20px;
      height: 20px;
    }

    .header__logout-text,
    .header__add--user {
      display: none;
    }
  }
}
