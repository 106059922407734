.ant-breadcrumb {
  .ant-breadcrumb-separator {
    display: none;
  }

  & > span:not(:first-child) .breadcrumb-item::before {
    content: '';
    position: absolute;
    clip-path: polygon(50% 50%, -50% -50%, 0 100%);
    height: var(--breadcrumb-height);
    width: var(--breadcrumb-height);
    background-color: #fff;
    left: 0;
    z-index: 1;
  }

  a {
    display: inline-block;
    padding: 0 0 0 20px;
    background-color: $secondary-color;
    transition: background 0.2s linear;
  }

  & > span:first-child {
    .breadcrumb-item {
      margin-left: 0;
    }
    a {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      .breadcrumb-item-icon {
        margin-left: -10px;
      }
    }
  }

  & > span:last-child .breadcrumb-item {
    a {
      background-color: $primary-color;
    }
    a::after {
      background-color: $primary-color;
    }
  }
}

.breadcrumb-header {
  height: 76px;
  button {
    height: 32px !important;
  }
  .ant-page-header-heading-left {
    overflow: visible;
  }
  .ant-page-header-heading-extra {
    .breadcrumb-extra-item:not(:first-child) {
      margin-left: 20px;
    }
  }
  .ant-page-header-heading-title {
    overflow: visible;
  }
}

.breadcrumb-item {
  --breadcrumb-height: 32px;
  display: inline-block;
  position: relative;
  height: var(--breadcrumb-height);
  line-height: var(--breadcrumb-height);
  margin-left: 4px;
  .breadcrumb-item-link {
    font-size: 20px;
    color: #fff;
  }
  a::after {
    content: '';
    transition: background 0.2s linear;
    position: absolute;
    clip-path: polygon(50% 50%, -50% -50%, 0 100%);
    height: var(--breadcrumb-height);
    width: var(--breadcrumb-height);
    background-color: $secondary-color;
    left: 100%;
    z-index: 2;
  }

  &:hover a {
    color: #fff;
    background-color: rgba(0, 21, 41, 0.7);
    &::after {
      background-color: rgba(0, 21, 41, 0.7);
    }
  }

  &-icon svg {
    font-size: 20px;
  }
}

.ant-breadcrumb > span:last-child a {
  color: #fff;
}
