.site-layout-background .main__content {
  background: #fff !important;
}

.ant-modal-footer {
  text-align: unset !important;
}

.ant-drawer-footer {
  left: 0;
  right: 0;
  background: #fff;
}

.ant-page-header-heading-title {
  font-weight: 500 !important;
}

.main__content {
  background-color: #fff;
  padding-top: 64px;
  padding-left: 200px;
  &.collapsed {
    padding-left: 80px;
  }
}

.ant-page-header {
  padding: 16px 24px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05) !important;
}

.box-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .load {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      text-transform: uppercase;
    }
    img {
      width: 50px;
      margin-bottom: 15px;
    }
  }
}
