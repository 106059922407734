.release-config {
  .box-list {
    padding: 0;
  }
}

.release-view {
  min-height: 50vh;
  border-radius: 10px;
  margin-top: 50px;
  .release-content-wrapper {
    padding: 30px;
    border: 1px dashed rgb(207, 207, 207);
  }
  .number {
    font-weight: 700;
    font-size: 15px;
  }
  .release-date {
    margin-left: 12px;
    font-size: 13px;
    color: #9d9d9d;
  }
}
