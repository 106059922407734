.addCv {
  margin-bottom: 16px;
  .show {
    margin-bottom: 16px;
    .avatar-show {
      margin: auto;
      margin-top: 0;
      padding: 16px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .addCv {
    .show {
      .avatar-show {
        padding: 34px;
      }
    }
    .create-cv-content {
      justify-content: space-between;
    }
  }
}
