.request {
  &__form {
    .ant-row.ant-form-item {
      margin-bottom: 10px !important;
    }
    .ant-form-item-label {
      padding-bottom: 0;
    }
  }

  .ant-picker {
    width: 100%;
  }
}

@media (max-width: $md) {
  .request-drawer {
    .ant-drawer-body {
      padding-top: 0;
    }
  }
  .request-desktop {
    display: none;
  }
  .request-mobile {
    padding: 0;
    padding-top: 20px;
    .ant-row {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      flex-direction: column;
      button[type='submit'] {
        margin-left: 0;
      }
      .ant-col {
        max-width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-row.ant-form-item {
    margin-bottom: 0 !important;
  }
}
