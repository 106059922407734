@import 'variables';
@import 'react-image-lightbox/style.css';
@import './common';
@import './header';
@import './sidebar';
@import './notfound';
@import './layout';
@import './login';
@import 'position';
@import './statistic';
@import './tableMain';
@import './plan';
@import './request';
@import './cv';
@import './settings';
@import './nopermission';
@import './addCvNew.scss';
@import './inputTag';
@import './breadcrumb';
@import './utils';

.tox .tox-statusbar__path {
  display: none !important;
}
