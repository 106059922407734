.form-list-item-wrapper {
  .ant-form-item-control-input-content {
    display: flex;
    .ant-form-item {
      flex: 1;
    }
    .dynamic-delete-button {
      font-size: 20px;
      margin-top: 6px;
      margin-left: 8px;
    }
    .ant-form-item-extra {
      //color: $info-color-hover;
    }
  }
}
.range-title {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.range-title li {
  margin-left: 20px;
  margin-top: -90px;
  font-size: smaller;
}
#range-title-belowAve {
  padding-left: 50px;
  color: #ff0000;
}
#range-title-average {
  padding-left: 125px;
  color: #ffa500;
}
#range-title-good {
  padding-left: 14px;
  color: #ffa500;
}
#range-title-excellent {
  color: #ffa500;
  padding-right: 3px;
}

.slider-example {
  .ant-slider-track {
    background-color: #ff0000 !important;
    width: 50% !important;
  }
  .ant-slider-handle {
    display: none !important;
  }
}
