.table-fullscreen {
  background-color: white;
  overflow: auto;
  .action-container {
    cursor: context-menu;
    margin: -8px;
  }
  .action-icon {
    cursor: pointer;
    font-size: 22px;
    width: 48%;
    display: inline-block;
  }
  .action {
    display: flex;
    justify-content: space-between;
    margin: 8px 0;
    .action-all {
      display: flex;
      align-items: center;
      button {
        font-size: 12px;
        padding: 0 8px;
        height: 24px;
        span {
          line-height: 24px;
        }
      }
    }
    .btn-action {
      padding: 0 10px;
      border: 0;
      background: none;
      cursor: pointer;
      font-size: $icon-size;
      color: $text-color;
      .export-icon {
        position: relative;
        top: -3px;
      }
    }
    .btn-action:last-child {
      padding-right: 0;
    }
  }
  .ellipsis {
    font-size: 25px;
    color: $text-color;
    cursor: pointer;
  }
}
.ant-table-tbody {
  tr:hover td {
    background-color: $primary-color-light-hover !important;
  }
}
.table-fullscreen.fullscreen-enabled {
  padding: 1.25rem;
  .ant-table {
    overflow-y: scroll;
  }
  .ant-table-body {
    overflow: visible !important;
  }
  .ant-table-header {
    overflow: visible !important;
  }
  .ant-table-container::after {
    box-shadow: none;
  }
}

.table-main {
  margin-top: 13px;
  &__header {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    .action {
      margin-left: auto !important;
    }
  }
}

.overlayActionContent .ant-popover-inner-content {
  padding: 0 !important;
  .action-content {
    display: flex;
    flex-direction: column;
    button {
      padding: 20px 38px;
      display: flex;
      align-items: center;
      border: none !important;
      border: 1px solid transparent !important;
      border-radius: 0 !important;
    }
  }
}

.box-list {
  padding: 0 24px;
  margin-bottom: 16px;

  .box-shadow {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    padding: 0 10px;
  }
}

.search-input {
  padding: 20px;
}

.ant-table-row {
  cursor: pointer;
}
