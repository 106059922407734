@import 'variables';

// Fix giao diện chung
::selection {
  color: #fff;
  background: #1890ff;
}

.ant-form-item-required::before {
  content: '' !important;
  display: none !important;
}

.ant-drawer-close {
  order: 1;
  color: #000;
  padding-right: 0;
}

.ant-drawer-header {
  box-shadow: 0px 2px 8px rgb(0 0 0 / 15%);
  z-index: 10000;
}

.field--required {
  span {
    color: $error-color;
  }
}

.ant-message {
  z-index: 2001;
}

.ant-row.ant-form-item {
  margin-bottom: 12px;
}

.standard-form {
  .ant-row.ant-form-item {
    margin-bottom: 15px;
    .ant-col.ant-form-item-label {
      padding-bottom: 2px;
    }
  }
  .ant-row.ant-form-item.submit-btn {
    margin-bottom: 0;
  }
  .ant-row.ant-form-item.ant-form-item-has-error {
    margin-bottom: 5px;
  }
}

// Form
.form-group {
  display: flex;
  gap: 2rem;
  & > * {
    flex: 1;
  }
}

.multipleDelete--content {
  & > span:not(:last-of-type) {
    margin-right: 5px;
  }
}

// Badge
.age-badge {
  background: slategray;
  color: white;
  min-width: 67px;
  display: flex;
  justify-content: center;
  padding: 2px 7px;
  border-radius: 9px;
  font-weight: 400;
  width: fit-content;
}
.level-badge {
  background: darkseagreen;
  color: white;
  padding: 1px 3px;
  border-radius: 4px;
  width: fit-content;
}

// Responsive breakpoints
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

:export {
  xs: $xs;
  sm: $sm;
  md: $md;
  lg: $lg;
  xl: $xl;
}

.white-space-nowrap {
  white-space: nowrap;
}
