//page header
.header-list {
  h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }
  padding: 22px 24px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
  .buttons {
    button + button {
      margin-left: 10px;
    }
  }
}
