.cv__managerment {
  .ant-modal-footer {
    border-top: none;
  }
  .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-label {
      padding-bottom: 0;
    }
  }
}

//import cv
.file-uploader {
  display: flex;
  width: 100%;
  justify-content: center !important;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px dashed #ccc;
  flex-direction: column;
  gap: 20px;
}
.upload {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.table-drawer {
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}
.danger-text {
  color: $error-color;
  margin-bottom: 0;
  margin-top: 10px;
}
.download-link {
  margin-top: 70px !important;
  margin-bottom: 0;
}
.note {
  margin-bottom: 20px;
}
.error-file {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: $xs) {
  .cv-basic-info {
    flex-direction: column;
    gap: 4px !important;
    align-items: flex-start !important;
  }
}
