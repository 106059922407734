.permission-box {
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 420px;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 15px;
  .ant-checkbox-group {
    width: 100%;
  }
  table {
    text-align: center;
    width: 100% !important;
    tr {
      height: 30px;
    }
    .thead-title {
      width: 30%;
    }
    .text-left {
      text-align: left;
    }
  }
}
